import React, { useEffect } from "react";
import { Container, Typography } from "@mui/material";
import { graphql } from "gatsby";
import Layout from "../../components/layout";
import EventMaterials from "../../components/event-materials";
import Brick from "../../components/bricks/bricks";
import BrickVimeoLiveEvent from "../../components/bricks/vimeo-live-event";
import BrickImageText from "../../components/bricks/image-text";
import Breadcrumb from "../../components/breadcrumbs/breadcrumb";
import EventRegistration from "../../components/event-registration";
import { useI18next } from "gatsby-plugin-react-i18next";
import { formatAlternatePath } from "../../utils/alternate-lang";
import { isSeeMore, isFullLength } from "../../utils";
import { useTheme } from "@mui/styles";
// import PrivateRoute from "../../components/private-route";
import Agenda from "../../components/agenda";
import { gql, useMutation } from "@apollo/client";
import BrickNewsletter from "../../components/bricks/newsletter";
import BrickRichText from "../../components/bricks/rich-text";
import { gtmPushUserInfo } from "../../services/auth";

export const query = graphql`
  fragment StrapiNewsletterLiveEventsFragment on StrapiNewsletterLiveEvents {
    Newsletter {
      title
      subtitle
      background {
        alternativeText
      }
    }
    backgroundImage {
      localFile {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  }
  query LiveEvent($Slug: String!, $language: String!) {
    liveEvent: strapiLiveEvent(Slug: { eq: $Slug }) {
      Slug
      AlternateSlug
      Title
      description {
        richText
      }
      metaDescription
      date
      headerImage {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      icsFile {
        localFile {
          publicURL
        }
      }
      eventBackground {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      location
      agenda
      pageContent
      vimeoEventId
      chatEventId
      therapeutical_area {
        Name
      }
      pageContentConstrainedImages {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
      pageContentFullWidthImages {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      pageContentSeeMoreEventsThumbs {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 276, height: 223)
          }
        }
      }
      pageContentSeeMoreCoursesThumbs {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 276, height: 223)
          }
        }
      }
      pageContentSeeMoreThumbs {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 276, height: 223)
          }
        }
      }
      pageContentDownloads {
        localFile {
          publicURL
        }
      }
      materials
      eventMaterialFullWidthImages {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      eventMaterialConstrainedImages {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      eventMaterialDownloads {
        localFile {
          publicURL
        }
      }
    }

    strapiNewsletterLiveEventsEn: strapiNewsletterLiveEvents(
      locale: { eq: "en" }
    ) {
      ...StrapiNewsletterLiveEventsFragment
    }
    strapiNewsletterLiveEventsEs: strapiNewsletterLiveEvents(
      locale: { eq: "es-PA" }
    ) {
      ...StrapiNewsletterLiveEventsFragment
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const UPDATE_USER_EVENTS = gql`
  mutation ($id: ID!, $subscribedEvents: JSON!) {
    updateUser(
      input: {
        where: { id: $id }
        data: { subscribedEvents: $subscribedEvents }
      }
    ) {
      user {
        id
        userLastName
        userFirstName
        country
        profession
        areasOfExpertise
        email
        progress
        quizzResults
        subscribedEvents
        subscribedNewsletter
      }
    }
  }
`;

const LiveEventComponent = ({ data }) => {
  const theme = useTheme();
  const { t, language } = useI18next();

  const [updateUserEvents, { loading: loadingUpdateUserEvents }] =
    useMutation(UPDATE_USER_EVENTS);

  useEffect(() => {
    window.dataLayer?.push({
      event: "page_info",
      page_language: language,
      page_category: "Live_Events",
      page_ta: data.liveEvent.therapeutical_area.Name,
      page_type: "Live_Event",
    });
    gtmPushUserInfo();
  }, [language, data]);

  if (!data) return null;

  const { headerImage, agenda } = data.liveEvent;
  const newsletter =
    language === "en"
      ? data.strapiNewsletterLiveEventsEn
      : data.strapiNewsletterLiveEventsEs;
  const alternateLang = language === "en" ? "es" : "en";
  const seo = {
    metaTitle: data.liveEvent.Title,
    metaDescription: (data.liveEvent.metaDescription || "")
      .split("\n")
      .join(" "),
    linkAlternateHref: data.liveEvent.AlternateSlug
      ? `${formatAlternatePath(
          data.liveEvent.AlternateSlug,
          "live-events",
          alternateLang
        )}`
      : null,
    linkHrefLang: alternateLang,
  };

  return (
    <Layout
      seo={seo}
      alternateHref={
        data.liveEvent.AlternateSlug
          ? formatAlternatePath(data.liveEvent.AlternateSlug, "live-events")
          : null
      }
    >
      <div>
        {headerImage && (
          <BrickImageText
            title=""
            gatsbyImageData={
              headerImage.localFile.childImageSharp.gatsbyImageData
            }
            alternativeText={
              headerImage.alternativeText ? headerImage.alternativeText : ""
            }
            mobileImageStyle={{
              maxHeight: "200px",
            }}
            isHeader={true}
          />
        )}
        <Container style={{ paddingLeft: 0, paddingRight: 0 }}>
          <Container
            sx={{ my: 0 }}
            style={{ paddingLeft: "16px", paddingRight: "16px" }}
          >
            <Breadcrumb
              title={data.liveEvent.Title}
              parentTitle={t(`nav.events`)}
              parentLink="/live-events"
            />
          </Container>
          <Container
            sx={{ marginTop: 4, marginBottom: { xs: 1, md: 4 } }}
            style={{ paddingLeft: "16px", paddingRight: "16px" }}
          >
            <Typography variant="h1">{data.liveEvent.Title}</Typography>
          </Container>
          <Container sx={{ my: 0 }} style={{ paddingLeft: 0, paddingRight: 0 }}>
            <BrickVimeoLiveEvent
              backgroundImageData={
                data.liveEvent.eventBackground.localFile.childImageSharp
                  .gatsbyImageData
              }
              eventId={data.liveEvent.vimeoEventId}
              chatId={data.liveEvent.chatEventId}
              style={{ paddingLeft: "16px", paddingRight: "16px" }}
            />
          </Container>
          <Container
            sx={{ my: 4 }}
            style={{ paddingLeft: "16px", paddingRight: "16px" }}
          >
            <Typography variant="h3">{t("liveEvent.aboutTheEvent")}</Typography>
            <hr
              color={theme.palette.servier.lightBlue}
              style={{
                width: "65px",
                height: "8px",
                marginBottom: "2em",
                marginLeft: 0,
                marginTop: 0,
              }}
            ></hr>
            <BrickRichText
              style={{ padding: 0 }}
              text={
                data.liveEvent.description
                  ? data.liveEvent.description.richText
                  : ""
              }
            />
          </Container>
          <Container style={{ paddingLeft: "16px", paddingRight: "16px" }}>
            <Agenda agenda={agenda}></Agenda>
          </Container>
          <PageContent data={data} seeMore={false}></PageContent>
          <EventMaterials liveEvent={data.liveEvent}></EventMaterials>
        </Container>
        <PageContent data={data} seeMore={true}></PageContent>
        {newsletter && (
          <BrickNewsletter
            title={newsletter.Newsletter.title}
            subtitle={newsletter.Newsletter.subtitle}
            buttonText={newsletter.Newsletter.buttonText}
            backgroundImageData={
              newsletter.backgroundImage[0].localFile.childImageSharp
                .gatsbyImageData
            }
            alternativeText={newsletter.Newsletter.background.alternativeText}
            style={{ padding: 0 }}
          />
        )}
        <div
          style={{
            zIndex: 1,
            position: "-webkit-sticky" /* Safari */,
            position: "sticky",
            bottom: 0,
            px: 0,
            mx: 0,
          }}
        >
          <EventRegistration
            liveEvent={data.liveEvent}
            updateUserEvents={updateUserEvents}
          />
        </div>
      </div>
    </Layout>
  );
};

const PageContent = ({ data, seeMore }) => {
  const {
    pageContent,
    pageContentFullWidthImages,
    pageContentConstrainedImages,
    pageContentSeeMoreEventsThumbs,
    pageContentSeeMoreCoursesThumbs,
    pageContentSeeMoreThumbs,
    pageContentDownloads,
  } = data.liveEvent;

  const getImageContent = (i) => {
    return pageContentFullWidthImages[i]?.localFile !== null
      ? pageContentFullWidthImages[i]
      : pageContentConstrainedImages[i];
  };

  const show = (content, seeMore) => {
    if (seeMore) {
      return isSeeMore(content);
    } else {
      return !isSeeMore(content);
    }
  };

  return pageContent.map(
    (content, i) =>
      show(content, seeMore) && (
        <Container
          style={{ padding: 0 }}
          maxWidth={isFullLength(content) ? false : "xl"}
        >
          <Brick
            key={`content__${i}`}
            content={content}
            imageContent={getImageContent(i)}
            seeMoreThumbsContent={pageContentSeeMoreThumbs[i]}
            seeMoreEventsThumbsContent={pageContentSeeMoreEventsThumbs[i]}
            seeMoreCoursesThumbsContent={pageContentSeeMoreCoursesThumbs[i]}
            downloadsContent={pageContentDownloads[i]}
          />
        </Container>
      )
  );
};

const LiveEvent = LiveEventComponent;

// const LiveEvent = ({ data }) => {
//   return <PrivateRoute component={LiveEventComponent} data={data} />;
// };

export default LiveEvent;
