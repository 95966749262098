import { Container, Grid, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useEffect } from "react";
import { getVideoUrl } from "../../utils/video";
import { useMeasure } from "react-use";

const BrickVimeoLiveEvent = ({
  backgroundImageData,
  eventId,
  chatId,
  style,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const VIDEO_HEIGHT = 360;

  // Refresh vimeo to allow to update the GTM datalayer
  useEffect(() => {
    if (window.__vimeoRefresh) {
      window.__vimeoRefresh();
    }
  }, []);

  if (isMobile)
    return (
      <BrickVimeoLiveEventMobile
        backgroundImageData={backgroundImageData}
        eventId={eventId}
        chatId={chatId}
        style={style}
      />
    );

  return (
    <Container maxWidth="xl" sx={{ p: 0, height: "427px" }} style={style}>
      <div style={{ position: "relative" }}>
        <GatsbyImage
          alt={`Background Vimeo Live Event`}
          image={backgroundImageData}
          style={{ height: "427px" }}
        />
        <Grid
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            margin: "auto",
          }}
          display="flex"
          flexDirection={isMobile ? "column" : "row"}
          justifyContent="center"
          alignItems="center"
        >
          <iframe
            title="Event"
            src={getVideoUrl(eventId)}
            width="640"
            height={VIDEO_HEIGHT}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            style={{
              width: "640px",
              height: "315px",
              borderRadius: "15px",
              margin: 30,
            }}
          />
          {chatId && (
            <iframe
              title="Event"
              src={getVideoUrl(chatId)}
              width="640"
              height={VIDEO_HEIGHT}
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              style={{
                width: "640px",
                height: "315px",
                borderRadius: "15px",
                margin: 30,
              }}
            />
          )}
        </Grid>
      </div>
    </Container>
  );
};

const BrickVimeoLiveEventMobile = ({
  backgroundImageData,
  eventId,
  chatId,
  style,
}) => {
  const [ref, { width }] = useMeasure();
  const toPixel = (number) => number.toString().split(".")[0] + "px";
  const videoDimensionsMobile = {
    width: toPixel(width),
    height: toPixel((width * 231) / 343),
  };

  return (
    <Container ref={ref} sx={{ p: 0 }} style={style}>
      <Grid
        style={{
          margin: "auto",
        }}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <iframe
          title="Event"
          src={getVideoUrl(eventId)}
          width={videoDimensionsMobile.width}
          height={videoDimensionsMobile.height}
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          style={{
            width: videoDimensionsMobile.width,
            height: videoDimensionsMobile.height,
            borderRadius: "15px",
          }}
        />
        {chatId && (
          <iframe
            title="Event"
            src={getVideoUrl(chatId)}
            width={videoDimensionsMobile.width}
            height={videoDimensionsMobile.height}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            style={{
              width: videoDimensionsMobile.width,
              height: videoDimensionsMobile.height,
              borderRadius: "15px",
            }}
          />
        )}
      </Grid>
    </Container>
  );
};

export default BrickVimeoLiveEvent;
