import { Breadcrumbs, Container, Link, Typography } from "@mui/material";
import React from "react";

const Breadcrumb = ({
  title,
  parentTitle,
  parentLink,
  grandParentTitle,
  grandParentLink,
}) => {
  return (
    <Container
      sx={{ my: { md: 4, xs: 1 } }}
      style={{ paddingLeft: 0, paddingRight: 0 }}
    >
      <Breadcrumbs aria-label="breadcrumb">
        {grandParentLink && grandParentTitle && (
          <Link
            underline="hover"
            color="inherit"
            href={grandParentLink}
            style={{ textDecoration: "none" }}
          >
            {grandParentTitle}
          </Link>
        )}
        <Link
          underline="hover"
          color="inherit"
          href={parentLink}
          style={{ textDecoration: "none" }}
        >
          {parentTitle}
        </Link>
        <Typography color="text.primary">{title}</Typography>
      </Breadcrumbs>
    </Container>
  );
};

export default Breadcrumb;
