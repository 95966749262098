import { useTheme } from "@emotion/react";
import { Button, Container, Typography, Grid } from "@mui/material";
import React, { useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import { formatDate, getUsTime, isTodayOrAfter } from "../utils";
import { useI18next } from "gatsby-plugin-react-i18next";
import { getUser, setUserFromUpdateData } from "../services/auth";
import { useMeasure } from "react-use";
import { downloadFile } from "../utils";

const EventRegistration = ({ liveEvent, updateUserEvents }) => {
  const { t, language } = useI18next();
  const theme = useTheme();
  const agenda = liveEvent.agenda;
  const [isSubscribed, setisSubscribed] = useState(
    getUser()?.subscribedEvents?.includes(liveEvent.Slug)
  );

  const [ref, { width }] = useMeasure();
  const toPixel = (number) => number.toString().split(".")[0] + "px";
  const buttonWidth = { xs: toPixel(width - 32), sm: "100%" };

  const getUpdatedSubscribedEvents = (subscribedEvents, liveEventSlug) => {
    if (!subscribedEvents) {
      return JSON.parse(`["${liveEventSlug}"]`);
    } else {
      return subscribedEvents.concat(`${liveEventSlug}`);
    }
  };

  const subscribe = () => {
    updateUserEvents({
      variables: {
        id: getUser().id,
        subscribedEvents: getUpdatedSubscribedEvents(
          getUser().subscribedEvents,
          liveEvent.Slug
        ),
      },
      headers: {
        Authorization: `Bearer ${getUser().token}`,
      },
    })
      .then((data) => {
        // Handle success.
        setUserFromUpdateData(data, getUser().token);
        setisSubscribed(true);
        downloadFile(liveEvent.Slug + "-calendar.ics", liveEvent.icsFile);
      })
      .catch((error) => {
        // Handle error.
        console.log(
          "update user subscribed events : an error occurred:",
          error.graphQLErrors[0]?.extensions.exception.output.message[0]
            .messages[0].message
        );
      });
  };

  const getEventHoroDate = () => {
    const dateString = formatDate(
      liveEvent.date,
      language === "en" ? "en-US" : "es-PA"
    );
    if (!agenda || agenda.length === 0) {
      return dateString;
    }
    const startTime = getUsTime(agenda[0].start);
    const endTime = getUsTime(agenda[agenda.length - 1].end);
    return dateString + " " + startTime + " - " + endTime;
  };

  if (isTodayOrAfter(liveEvent.date) || !getUser()?.token) return null;

  return (
    <Container
      ref={ref}
      maxWidth="false"
      sx={{
        padding: 0,
        margin: 0,
        backgroundColor: "white",
      }}
      style={{
        border: "solid",
        borderWidth: "1px 0 0 0",
        borderColor: theme.palette.grey.servier_5,
        boxShadow: "0px 4px 18px rgba(0, 0, 0, 0.2)",
      }}
    >
      <Container
        sx={{
          mx: { xs: 0, md: "auto" },
          padding: { xs: 0, lg: 0 },
        }}
        style={{
          paddingTop: { xs: 1, lg: "1em" },
          paddingBottom: { xs: 1, lg: "1em" },
          display: "flex",
          justifyContent: { xs: "start", lg: "space-between" },
          alignItems: { xs: "start", lg: "center" },
        }}
      >
        <Grid
          container
          sx={{ px: { xs: 0, lg: 2 }, py: 2, justifyContent: "flex-start" }}
          maxWidth="xl"
        >
          <Grid item xs={12} md={6}>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: "900",
                lineHeight: "25px",
              }}
              sx={{
                paddingTop: { xs: 0, md: 1 },
                paddingBottom: 1,
                px: { xs: 2, sm: 0 },
              }}
            >
              {liveEvent.Title}
            </Typography>
            <Typography
              sx={{
                paddingTop: { xs: 0, md: 1 },
                paddingBottom: 1,
                px: { xs: 2, sm: 0 },
              }}
            >
              {getEventHoroDate()}
            </Typography>
            <Typography
              sx={{
                paddingTop: { xs: 0, md: 1 },
                paddingBottom: 1,
                px: { xs: 2, sm: 0 },
              }}
            >
              {liveEvent.location}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{ marginLeft: "auto", marginRight: "auto" }}
          >
            <Button
              onClick={() => {
                subscribe(true);
              }}
              variant="contained"
              startIcon={isSubscribed ? <CheckIcon /> : null}
              sx={{
                py: 2,
                mx: { xs: 2, sm: 0 },
                width: buttonWidth,
              }}
              style={{
                fontFamily: "Roboto",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "900",
                lineHeight: "16px",
                letterSpacing: "0.03em",
                backgroundColor: isSubscribed ? "#95C11F" : null,
              }}
            >
              {isSubscribed
                ? t("liveEvent.subscribed")
                : t("liveEvent.subscribe")}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
};

export default EventRegistration;
