import React from "react";
import { Container, Typography } from "@mui/material";
import Brick from "../components/bricks/bricks";
import { useI18next } from "gatsby-plugin-react-i18next";
import { useTheme } from "@emotion/react";
import { isTodayOrAfter } from "../utils";

const EventMaterials = ({ liveEvent }) => {
  const { t } = useI18next();
  const theme = useTheme();
  const {
    materials,
    eventMaterialFullWidthImages,
    eventMaterialConstrainedImages,
    eventMaterialDownloads,
  } = liveEvent;

  const showMaterials = () => {
    return (
      liveEvent.materials &&
      liveEvent.materials.length &&
      isTodayOrAfter(liveEvent.date)
    );
  };
  const getImageContent = (i) => {
    return eventMaterialFullWidthImages[i]?.localFile !== null
      ? eventMaterialFullWidthImages[i]
      : eventMaterialConstrainedImages[i];
  };

  if (!showMaterials()) return null;
  return (
    <Container
      maxWidth="lg"
      sx={{
        my: 8,
        mx: "auto",
        px: 0,
        py: 0,
      }}
    >
      <Container sx={{ px: 2 }}>
        <Typography variant="h3">{t("liveEvent.materials")}</Typography>
        <hr
          color={theme.palette.servier.lightBlue}
          style={{
            width: "65px",
            height: "8px",
            marginBottom: "2em",
            marginLeft: 0,
            marginTop: 0,
          }}
        ></hr>
      </Container>
      {materials.map((material, i) => (
        <Brick
          key={`content__${i}`}
          content={material}
          imageContent={getImageContent(i)}
          downloadsContent={eventMaterialDownloads[i]}
          smallTitle={true}
        />
      ))}
    </Container>
  );
};

export default EventMaterials;
