import React from "react";
import { Container, Typography, Grid } from "@mui/material";
import { useI18next } from "gatsby-plugin-react-i18next";
import { useTheme } from "@emotion/react";
import { getUsTime } from "../utils";

const Agenda = ({ agenda }) => {
  const { t } = useI18next();
  const theme = useTheme();

  if (!agenda || agenda.length === 0) return null;
  return (
    <Container
      maxWidth="lg"
      sx={{
        my: 8,
        mx: "auto",
      }}
      style={{ padding: 0 }}
    >
      <Typography variant="h3">{t("liveEvent.agenda")}</Typography>
      <hr
        color={theme.palette.servier.lightBlue}
        style={{
          width: "65px",
          height: "8px",
          marginBottom: "2em",
          marginLeft: 0,
          marginTop: 0,
        }}
      ></hr>
      {agenda.map((session) => (
        <AgendaSession session={session}></AgendaSession>
      ))}
    </Container>
  );
};

const AgendaSession = ({ session }) => {
  const theme = useTheme();
  const period = getUsTime(session.start) + " - " + getUsTime(session.end);

  return (
    <Container
      maxWidth="lg"
      sx={{
        py: 1,
        border: "solid",
        borderWidth: "0 0 1px 0",
        borderColor: theme.palette.grey.servier_4,
      }}
      style={{ paddingLeft: 0, paddingRight: 0 }}
    >
      <Grid container>
        <Grid item xs={6}>
          <Typography>{session.title}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography align="right">{period}</Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Agenda;
